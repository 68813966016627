<template>
  <div>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Manage report (Practical exam)</h4>
                  <div class="breadcrumb-sub-header">
<!--                    <router-link to="/dashboard">Dashboard </router-link>\ Manage report (Practical exam)-->
                  </div>
                </div>
                <div class="breadcrumb-right">
                  <div class="card-toolbar">
                    <v-btn  
                      @click="downloadAllReport"
                      class="mt-4 mr-3 text-white"
                      color="blue"
                      v-if="practical_exam_results && practical_exam_results.length > 0"
                      :loading="isAllDownloading"
                    >
                      <v-icon small elevation="2" outlined>fas fa-download</v-icon>&nbsp;
                      Download all files as zip
                    </v-btn>
                    <v-btn  @click="createOrUpdateResult()" class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                      Add new
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" v-if="isLoading">
              <v-skeleton-loader
                  type="table-thead">
              </v-skeleton-loader>
            </div>
            <div class="card-body" v-else>
              <v-row no-gutters>
                <v-col cols="12">
                  <p>Only the Official document will be issued to the Candidate. All others will remain hidden in the archive, accessible only by admin and the author. Additional documents can be shared manually but will appear as attachments, not as an exam report.</p>
                </v-col>
                <v-col>
                  <p><b>Enrolment key:</b> {{candidate_summary.exam_key}}</p>
                  <p><b>Exam name:</b> {{candidate_summary.exam_name}}</p>
                  <p><b>Exam date:</b> {{candidate_summary.exam_date}}</p>
                  <p v-if="candidate_summary.examiner_lists && candidate_summary.examiner_lists.length > 0">
                    <b>Examiners:</b>
                    <div v-for="(examiner, idx) in candidate_summary.examiner_lists">
                      {{examiner.first_name}} {{examiner.last_name}}
                    </div>
                  </p>
                  <div >
<!--                    <span class="badge badge-primary mr-2" v-if="candidate_summary.is_result_available_to_user">Result visible to enroller</span>-->
                    <span class="badge badge-primary"  v-if="candidate_summary.is_result_available_to_candidate">Result visible to candidate</span>
                  </div>
                </v-col>
                <v-col>
                  <p><b>Candidate name:</b> {{candidate_summary.full_name}}</p>
                  <p><b>Schedule:</b> {{candidate_summary.schedule_name}}</p>
                  <p><b>Exam time:</b> {{candidate_summary.exam_start_time}} - {{candidate_summary.exam_end_time}}</p>
                  <p v-if="candidate_summary.supervisor_lists && candidate_summary.supervisor_lists.length > 0"><b>Supervisors:</b> 
                    <div v-for="(supervisor, idx) in candidate_summary.supervisor_lists">
                      {{supervisor.first_name}} {{supervisor.last_name}}
                    </div>
                  </p>
                </v-col>
              </v-row>
              <div class="" style="table-layout: fixed">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="isLoading">
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="isLoading"
                    type="table-row-divider@25">
                </v-skeleton-loader>
                <table class="table" v-if="!isLoading">
                  <thead>
                  <tr class="px-3">
                    <th class="px-3">Examiner name</th>
                    <th class="px-3">Report title</th>
                    <th class="px-3">Mark obtained</th>
                    <th class="px-3">Report type</th>
                    <th class="px-3">Official document</th>
                    <th class="px-3">Date submitted</th>
                    <th class="pr-3 text-center">Options</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template>

                    <tr v-if="practical_exam_results.length > 0" v-for="item in practical_exam_results">

                      <td class="px-3">
                        <a @click="openExaminer(item.examiner_id)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                          {{item.examiner_name }}
                        </a>
                        <span class="badge badge-success" v-if="item.examiner_id == item.chief_examiner_id">Chief Examiner</span>
                      </td>
                      <td class="px-3">
                        <a @click="createOrUpdateResult(item.id)" class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                          {{item.title }}
                        </a>
                        <span class="badge badge-warning" v-if="item.is_confirm_result==0 && item.report_type=='digital' && item.is_main==0">
                            Draft
                        </span>
                        <div class="mt-1" v-if="item.certificate_print_status">
                          <span class="badge badge-primary">
                            Certificate printed on {{item.certificate_printed_date}}
                          </span>
                        </div>
                        <div class="mt-3" v-if="item.result_notified">
                          <span class="badge badge-primary">
                            Result sent on {{item.result_notified_date}}
                          </span>
                        </div>
                      </td>
                      <td class="px-3">
                        <span class="ml-10">{{item.mark_obtained_display_text}}</span>
                      </td>
                      <td class="px-2">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                            {{item.report_type=='scanned'? 'Scanned':'SCORE' }}
                        </span>


                      </td>
                      <td class="px-2">
                       <span class="badge font-size-lg ml-1" :class="item.is_main==1 ? 'badge-info' : 'badge-warning' ">
                         {{item.is_main ? 'Yes' : 'Draft' }}</span>
                      </td>
                      <td>
                        {{item.report_added_date}}
                      </td>
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="createOrUpdateResult(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                  <span class="navi-text">Edit report</span>
                                </a>
                              </b-dropdown-text>
                              
<!--                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="confirmPracticalExamReport(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i :class="item.is_confirm_result==1 ? 'fa fa-unlock':'fa fa-lock' "></i>
                                </span>
                                  <span class="navi-text">{{item.is_confirm_result==1 ?'Set Unconfirm':'Set Confirm'}}</span>
                                </a>
                              </b-dropdown-text>-->

                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="setAsMain(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-address-card"></i>
                                </span>
                                  <span class="navi-text">{{item.is_main==1 ?'Set as archive':'Set as official'}}</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item">
                                <a  @click="previewReport(item)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-eye"></i>
                                </span>
                                  <span class="navi-text">Preview report</span>
                                </a>
                              </b-dropdown-text>
                              
                              <b-dropdown-text v-if="item.is_main==1 && item.is_examiner_unlock_requested == 0 && item.is_confirm_result == 1" tag="div" class="navi-item">
                                <a @click="unlockReport(item)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-unlock"></i>
                                </span>
                                  <span class="navi-text">Unlock report</span>
                                </a>
                              </b-dropdown-text>
                              
                              <b-dropdown-text v-if="item.is_examiner_unlock_requested && item.is_confirm_result" tag="div" class="navi-item">
                                <a  @click="unlockMarkingForExaminer(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-lock"></i>
                                </span>
                                  <span class="navi-text">Unlock marking for examiner</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" v-if="item.is_main" class="navi-item">
                                <a @click="previewPrintCertificate(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-user-graduate"></i>
                                </span>
                                  <span class="navi-text">Print preview certificate</span>
                                </a>
                              </b-dropdown-text>
                              
                              <b-dropdown-text tag="div" v-if="item.exam_status == 'assessed'" class="navi-item">
                                <a @click="viewMarksObtained(item)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-chart-line"></i>
                                </span>
                                  <span class="navi-text"> View result details</span>
                                </a>
                              </b-dropdown-text>

<!--                              <b-dropdown-text tag="div" class="navi-item" v-if="item.report_type == 'scanned'">-->
<!--                                <a @click="updateReport(item)" class="navi-link">-->
<!--                                <span class="navi-icon">-->
<!--                                    <i class="fas fa-file-alt"></i>-->
<!--                                </span>-->
<!--                                  <span class="navi-text">Change report </span>-->
<!--                                </a>-->
<!--                              </b-dropdown-text>-->


                              
                              <b-dropdown-text tag="div" class="navi-item">
                                <a  @click="deleteReport(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                  <span class="navi-text">Delete report</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="practical_exam_results.length == 0">
                      <td colspan="7" class="text-center">
                        <strong>No items added</strong>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
                <b-pagination
                    v-if="practical_exam_results.length > 0"
                    class="pull-right mt-7"
                    @input="getAllPracticalExamResult"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                    :disabled="isLoading"
                ></b-pagination>
              </div>
            </div>
            </div>
          </div>

        <!-- marks obtained modal box -->
        <v-dialog
            v-model="showMarksObtainedDialog"
            max-width="800"
            scrollable
        >
            <v-card>
                <v-toolbar dark>
                    <v-card-title>
                        <span> Mark obtained </span>
                        <hr>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12">
                                <div class="text-h6">
                                    Mark type : {{result ? result.mark_type_text ? result.mark_type_text : 'NA' : 'NA'}}
                                </div>
                                <div class="text-h6">
                                    Mark obtained : {{result ? result.mark_obtained : ''}}
                                </div>
                                <div class="text-h6">
                                  Examiner : {{result ? result.examiner_name : 'NA'}}
                                </div>
                                <div v-if="result && result.nominated_for_prize" class="text-h6 mt-2">
                                  <i class="light-green--text"> 
                                    <i class="fa-solid fa-medal"></i>
                                    Nominated for prize 
                                  </i>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="btn btn-error"
                        dark
                        medium
                        @click="closeDialog"
                    >
                    Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- marks obtained modal box ends -->

        <!-- marks obtained modal box -->
        <v-dialog
            v-model="reportFile"
            max-width="800"
            scrollable
        >
          <v-card>
            <v-toolbar dark>
              <v-card-title>
                <span> Upload new report file </span>
                <hr>
              </v-card-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-file-input
                      v-model="practical_result.uploadFile"
                      :error="$v.practical_result.uploadFile.$error"
                      prepend-icon=""
                      prepend-inner-icon="mdi-file"
                      outlined
                      dense
                    >
                      <template v-slot:label>
                        Upload File <span class="text-danger">*</span>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="cancel-btn"
                  text
                  x-large
                  @click="closeReport"
              >
                Close
              </v-btn>
              <v-btn
                  color="btn btn-error"
                  dark
                  x-large
                  @click="uploadReport"
                  :loading="loading"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- marks obtained modal box ends -->
        <unlock-report-modal
          ref="unlock-report-modal"
          @refresh="getCandidateSummary()"
        ></unlock-report-modal> 

      </div>
    </v-app>
  </div>
</template>
<script>
import CandidatePracticalExamResultService from "@/services/candidate/practical-exam-result/CandidatePracticalExamResultService";
import UnlockReportModal from "./UnlockReportModal";
import CandidateService from "@/services/candidate/CandidateService";
import CandidateAttachmentService from "@/services/candidate/attachment/CandidateAttachmentService";
import {required} from "vuelidate/lib/validators";

const candidate = new CandidateService();
const candidatePracticalExamResult=new CandidatePracticalExamResultService();
const candidateAttachment = new CandidateAttachmentService();

export default {
  name: "ViewPracticalExamReport",
  components: {UnlockReportModal},
  data() {
    return {
      isLoading:true,
      loading: false,
      search: {},
      total: null,
      perPage: null,
      page: null,
      practical_exam_results:[],
      candidate_summary:{},
      isAllDownloading: false,
      showMarksObtainedDialog: false,
      reportFile: false,
      result: '',
      practical_result: {
        id: '',
        uploadFile: null
      },
    }
  },
  validations: {
    practical_result: {
      uploadFile: { required }
    },
  },
  methods:{
    openExaminer(examinerId) {
      this.$tabs.open({
        name: "examiner-edit",
        params: { examinerId: examinerId }
      });
    },
    updateReport(item) {
      this.reportFile = true;
      this.practical_result.id = item.candidate_id;
    },
    closeReport() {
      this.$v.$reset();
      this.reportFile = false;
      this.practical_result = {
        id: '',
        uploadFile: null
      };
    },
    uploadReport() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.loading = true;
        let formData = new FormData();
        formData.append('id', this.practical_result.id);
        formData.append('uploadFile', this.practical_result.uploadFile);
        candidateAttachment.update(this.practical_result.id, formData).then((response) => {
          this.$snotify.success("Update report Successfully");
          this.loading = false;
          this.closeReport();
          this.getCandidateSummary();
          this.practical_result = {
            id: '',
            uploadFile: null
          };
        }).catch(() => {
          this.loading = false;
        })
      }
    },
    createOrUpdateResult(resultId=null){
      if(resultId==null || resultId == undefined){
        this.$router.push({
          name:"practical-exam-result-report-create",
          params:{examKey:this.examKey},
        });
      } else{
        this.$router.push({
          name:'practical-exam-result-report-edit',
          params:{examKey:this.examKey,resultId:resultId}
        })
      }
    },
    getCandidateSummary(){
      this.isLoading = true;
      candidate
          .getCandidateSummary(this.examKey)
          .then((response) => {
            this.candidate_summary = response.data.candidate;
            this.getAllPracticalExamResult();

          })
          .catch((err) => {
          })
          .finally( () => {
            this.isLoading = false;
          })
    },
    getAllPracticalExamResult(){
      candidatePracticalExamResult
        .getAllPraticalResultByCandidate(this.search,this.page,this.candidate_summary.id)
          .then(response => {
            this.practical_exam_results=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {
          })
          .finally(() => {
          });
    },
    deleteReport(id){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidatePracticalExamResult
                .delete(id)
                .then((response) => {
                  this.getAllPracticalExamResult()
                  this.$snotify.success("Report deleted ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        }
      });
    },
    confirmPracticalExamReport(reportId){
      candidatePracticalExamResult
      .confirmPracticalExamResult(reportId)
      .then(response =>{
        this.getAllPracticalExamResult();
        this.$snotify.success("Report updated");
      })
    },
    previewReport(item){
      if(item.report_type=='scanned'){
        if(item.file_attachment){
          let popup = window.open(item.file_attachment, "_blank");
          if (!popup) {
            this.$snotify.warning('Please enable popup');
          }
        }else{
          this.$snotify.error("Report Not Found");
        }
      }
      else if(item.report_type == 'digital' && item.is_main){
        candidatePracticalExamResult
        // .getMainResult(item.id)
        .previewExamReport(item.id)
        .then(response => {
          let popup = window.open(response.data, "_blank");
          if (!popup) {
            this.$snotify.warning('Please enable popup');
          }

        })
        .catch(error => {
          this.$snotify.error("Report not found");
          this.isLoading = false;
        })
      }
      else{
        candidatePracticalExamResult
        .previewExamReport(item.id)
        .then(response => {

        })
        .catch(error => {
          this.isLoading = false;

        })
      }
    },
    previewPrintCertificate(reportId){
      candidatePracticalExamResult
        .previewPrintCertificate(reportId)
    },
    unlockMarkingForExaminer(resultId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidatePracticalExamResult
            .unlockMarkingForExaminer(resultId)
            .then((response) => {
              this.$snotify.success('Unlock request accept');
              this.getAllPracticalExamResult();
            })
            .catch((err) => {
              this.$snotify.error('Oops looks like something went wrong.');
            })
          }
        }
      });
    },
    setAsMain(reportId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidatePracticalExamResult
                .setAsMainExamReport(reportId)
                .then(response =>{
                  this.getAllPracticalExamResult();
                  this.$snotify.success("Report updated");
                })
          }
        }
      })
    },
    downloadAllReport(){
      let data = {
        candidate_id: this.candidate_summary.id,
        exam_key: this.candidate_summary.exam_key,
      };
      candidateAttachment
      .downloadAsZip(data, 'all')
    },
    viewMarksObtained(result){
      this.result = result;
      this.showMarksObtainedDialog = true;
    },
    closeDialog(){
      this.showMarksObtainedDialog = false;
    },
    unlockReport(item){
      if(item.is_examiner_unlock_requested){
        this.$snotify.error('The examiner was already requested to unlock the report, please check your unlock requests.');
      }else{
        this.$refs['unlock-report-modal'].openDialog(item.id);
      }
    }
  },
  computed:{
    examKey(){
      return this.$route.params.examKey;
    },
    routeTab(){
      if(this.candidate_summary){
        return {
          title: `${this.candidate_summary.full_name} - Exam Result Report`
        }
      }
    }
  },
  mounted() {
    this.getCandidateSummary();
  }
}
</script>