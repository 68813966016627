<template>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
        <v-card>
            <v-toolbar dark>
            <v-card-title>
                <span>Unlock report</span>
                <hr>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                <i class="fa fa-close"></i>
                </v-btn>
            </v-toolbar-items>
            </v-toolbar>
  
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <span class="font-size-base">Your Examiner will be emailed to let them know that the report is unlocked. Comments provided will be included in the email (eg instructions for things to edit).</span>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                outlined
                                dense
                                placeholder="Comments to provide to Examiner"
                                v-model="candidate_practical_exam_result.admin_user_unlock_notes"
                                :error="$v.candidate_practical_exam_result.admin_user_unlock_notes.$error"
                            >
                              <template v-slot:label>
                                Comments to provide to Examiner <span class="text-danger">*</span>
                              </template>
                            </v-textarea>
                            <span class="text-danger" v-if="$v.candidate_practical_exam_result.admin_user_unlock_notes.$error">This information is required</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    x-large
                    @click="closeDialog">
                Cancel
                </v-btn>
                <v-btn
                    dark
                    x-large
                    @click="unlockReport()"
                    :loading="loading"
                >
                Unlock report
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import CandidatePracticalExamResultService from "@/services/candidate/practical-exam-result/CandidatePracticalExamResultService";
    import {required} from "vuelidate/lib/validators";

    const candidatePracticalExamResult=new CandidatePracticalExamResultService();

    export default{
        validations:{
            candidate_practical_exam_result:{
                admin_user_unlock_notes: {required}
            }
        },
        data(){
            return {
                dialog: false,
                candidate_practical_exam_result:{
                    id: null,
                    admin_user_unlock_notes: ''
                },
                loading: false,
            }
        },
        methods:{
            closeDialog(){
                this.dialog = false,
                this.candidate_practical_exam_result = {
                    admin_user_unlock_notes: ''
                };
                this.loading = false;
            },
            openDialog(resultId){
                this.candidate_practical_exam_result.id = resultId;
                this.dialog = true;
            },
            unlockReport(){
                this.$v.$touch()
                if (this.$v.$error) {
                    setTimeout(() => {
                    this.$v.$reset()
                    }, 3000);
                } 
                else {
                    this.$confirm({
                        message: `Are you sure you want to unlock the report ? `,
                        button: {
                        no: "No",
                        yes: "Yes",
                    },
                    callback: (confirm) => {
                        if (confirm) {
                            this.loading = true;
                            candidatePracticalExamResult
                            .unlockReportByAdmin(this.candidate_practical_exam_result.id, this.candidate_practical_exam_result)
                            .then((res) => {
                                this.closeDialog();
                                this.$emit('refresh');
                                this.$snotify.success('Report unlocked successfully');
                                this.loading = false;
                            })
                            .catch((err) => {
                                this.loading = false;
                            })
                        }
                    }
                })
            }
        }
    }
}
</script>

