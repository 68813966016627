import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CandidateAttachmentService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/candidate-attachment';
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    paginate(data = {}, index = null) {

        let url = this.#api;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        if (index != null && !queryString)
            url = url + '?' + "page=" + (index);
        else
            url = url + '&' + "page=" + (index);
        return apiService.get(url);
    }
    update(id,data) {
        let url = `${this.#api}/${id}`
        return apiService.post(url,data)
    }
    delete(id){
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getByCandidate(candidateId){
        let url = `${this.#api}/${candidateId}/get-by-candidate`;
        return apiService.get(url);
    }

    getCandidateAttachmentsByType(type, data={},index = null, candidateId){
        let url = `${this.#api}/${candidateId}/get/${type}/all`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.get(url);
    }

    getByMultipleType(data, index = null, candidateId){
        let url = `${this.#api}/candidate/${candidateId}/get-multiple-types?types=${data}`;
        if (index != null)
            url = `${url}?page=${index}`;
        
        return apiService.get(url);
    }
    
    createMultipleReports(data) {
        let url = `${this.#api}/multiple/report`
        return apiService.post(url, data)
    }
    downloadAsZip(data, type){
        let url = `${this.#api}/download-zip/${type}?candidate=${data.candidate_id}&exam_key=${data.exam_key}&candidate_attachment_ids=${data.candidate_attachment_ids}`;
        window.open(url, "_blank");
    }
    changeAccountHolderVisibility(candidateAttachmentId){
        let url = `${this.#api}/${candidateAttachmentId}/change/account-holder/visibility`;
        return apiService.get(url)
    }

    updateReport(id,data) {
        let url = `${this.#api}/${id}/update-report`
        return apiService.post(url,data)
    }

    uploadReport(id,data) {
        let url = `${this.#api}/${id}/upload-report`
        return apiService.post(url,data)
    }
}